import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import Link from "../../link/link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import Hyphenate from "../../hyphenate/hyphenate";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNewsTimeline extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-news teaser-news-timeline">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <div
          className="flex-wrap"
        >
          {this.props.item.fieldTeaserbild &&
            <div className="img-wrap">
              <Image
                data={this.props.item.fieldTeaserbild.fieldMediaImage}
                nodeTitle={this.props.item.title}
                credit={this.props.item.fieldTeaserbild.fieldCredit}
              />
            </div>

          }
          <span className="date-wrap">
            <p>Meldung vom: </p>
            <FieldTime
              timestamp={true}
              date={
                this.props.item.publishedAtRawField?.first.value ||
                this.props.item.publishedAtRawField?.first.publishedAtOrNow
              }
              format={"DD.MM.YYYY"}
            />

          </span>

          <h3 className="news-titel"><Hyphenate>{this.props.item.title}</Hyphenate></h3>

          {this.props.item.fieldTeasertext && (
            <Hyphenate htmlString={true} className="teaser text">
              {this.props.item.fieldTeasertext}
            </Hyphenate>
          )}

          {this.props.item.fieldLink && (
            <Link link={this.props.item.fieldLink} className={"btn btn-transparent"}/>
          )}


          {/*
           {this.props.item.fieldSchlagwort.length > 0 &&
            this.props.item.fieldSchlagwort[0] &&
            this.props.item.fieldSchlagwort.map((item, index) => (
              <span key={index} className="tag">
                {item.name}
              </span>
            ))}
          */}
        </div>
      </article>
    );
  }
}

TeaserNewsTimeline.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNewsTimeline));
